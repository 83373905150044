<template>
  <div class="serve">
    <div class="line"></div>
    <div class="content">
      <div class="content_left">
        <img src="../../assets/our_service_1.png" alt="" />
      </div>
      <div class="content_right">
        <h1>西安港湾软件科技有限公司</h1>
        <div class="serve-ratata">
          <div ref="before" class="content_text" :class="isTop ? 'contain-Before' : ''">
            <div v-for="(item,index) in list" :key="index" class="item" @click="handleBefore(item.id)">
              <img :src="item.servePlan" alt="" />
              <p>{{ item.serveName }}</p>
            </div>
          </div>
          <div ref="after" class="content_opposite" :class="isTop ? 'contain-After' : ''">
            <div class="content_img">
              <div class="top">
                <img :src="content.contentPlan" alt="" />
              </div>
              <p>{{ content.serveName }}</p>
            </div>
            <div class="opposite_text" v-html="content.serveContent"></div>
            <div class="close">
              <img src="../../assets/service_close.png" alt="" @click="handleAfter" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {serveDetail, serveList} from '@/api/serve/serve.js'
export default {
  data() {
    return {
      isTop: false,
      list: [
        // {
        //   img: require('@/assets/manpower.png'),
        //   text: '技术服务'
        // },
        // {
        //   img: require('@/assets/product.png'),
        //   text: '标准化产品销售'
        // },
        // {
        //   img: require('@/assets/yunying.jpg'),
        //   text: '移动互联运营合作'
        // },
        // {
        //   img: require('@/assets/dingzhikaifa.png'),
        //   text: '软件定制开发'
        // }
      ],
      content: {
        // img: require('@/assets/manpower1.png'),
        // title: '技术服务',
        // desc: '<p>为什么越来越多的其他企业不选择自己招聘IT人员，也是选择IT人力外包呢？</p><p>1、低成本：在外包合作中，外包公司承担了人员招聘费用、员工试用风险、部分员工管理成本、资源配置和岗前培训成本以及员工福利等，在最大程度上降低项目成本，保障客户利益。</p>'
      }
    }
  },
  mounted() {
    serveList().then(res => {
      this.list = res.data.data
      console.log(this.list, '服务列表')
    })
    if (this.$route.query.id) {
      this.detail(this.$route.query.id)
      this.$refs.before.style.transform = 'rotateY(-180deg)'
      this.$refs.after.style.transform = 'rotateY(0deg)'
    }
  },
  methods: {
    detail(id) {
      serveDetail(id).then(res => {
        console.log(res)
        this.content = res.data.data
      })
    },
    handleBefore(id) {
      this.detail(id)
      if (this.$route.query.id) {
        this.$refs.before.style.transform = 'rotateY(-180deg)'
        this.$refs.after.style.transform = 'rotateY(0deg)'
      } else {
        if (!this.isTop) {
          this.isTop = true
        }
      }
    },
    handleAfter() {
      if (this.$route.query.id) {
        this.$refs.before.style.transform = 'rotateY(0deg)'
        this.$refs.after.style.transform = 'rotateY(-180deg)'
      } else {
        if (this.isTop) {
          this.isTop = false
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.serve{
// background-color: aqua;
   .line{
     height: 10px;
    width: 100%;
    margin-top: -10px;
    margin-bottom: 70px;
    background-color: transparent;
    background-image: -webkit-linear-gradient(left, #5bd8ff, #ff0000);
    box-shadow: inset 0 -2px 2px rgb(0 0 0 / 40%);
    border-radius: 3px 0 0 3px;
   }
   .content{
     width: 1200px;
    margin: 0 auto 130px auto;
    display: flex;
    justify-content: space-between;
    .content_left{
      width: 310px;
    }
    .content_right{
      width: 737px;
      >h1{
        font-size: 48px;
        font-family: "HYYuanLongHei-CEJ";
        letter-spacing: 5px;
        font-weight: 400;
      }
      .serve-ratata{
        // transform: rotateY(0deg);
        position: relative;
        // transition: all 1s linear;
        // transform-style: preserve-3d;
        // transform-origin: center center;
        margin-top: 67px;
        .content_text{
          width: 690px;
          display: flex;
          flex-wrap:wrap;
          position: absolute;
          top:0;
          left: 0;
          backface-visibility: hidden;
          transition: 1.5s;
          .item{
              width: 305px;
              height: 302px;
              margin-right: 40px;
              margin-bottom: 36px;
              box-shadow: #666 0px 0px 10px;
              cursor: pointer;
              img{
                margin-top: 49px;
                height: 136px;
                width: 305px;
                margin-bottom: 30px;
              }
              p{
                font-family: 微软雅黑;
                font-size: 26px;
                text-align: center;
              }
          }
        }
        .contain-Before{
          transform: rotateY(180deg);
        }
        .content_opposite{
           width: 644px;
           box-shadow: #666 0px 0px 10px;
          //  position: absolute;
          //  top: 0;
          //  left: 0;
           backface-visibility: hidden;
           transform: rotateY(-180deg);
           transition: 1.5s;
          //  margin-bottom: 130px;
          .content_img{
            width: 645px;
            height: auto;
            background-color: #3688E4;
            text-align: center;
            color: #fff;
            img{
              margin-top: 66px;
            }
            p{
              font-size: 30px;
              padding-bottom: 44px;
            }
          }
          .opposite_text{
             width: 597px;
              /* height: 127px; */
              margin: 50px auto 0 auto;
          }
          .close{
            margin-top: 71px;
            padding-bottom: 25px;
            text-align: center;
          }
        }
         .contain-After{
             transform: rotateY(0deg);
         }
      }
    }
   }
}
</style>