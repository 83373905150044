import request from '@/util/request.js'
// 服务信息列表
export function serveList(data) {
  return request({
    url: '/json/webServe/list',
    method: 'post',
    data
  })
}
// 服务详细信息
export function serveDetail(id) {
  return request({
    url: '/json/webServe/detail/' + id,
    method: 'get'
  })
}